import { Action } from '@ngrx/store';
import { LeadType } from 'src/app/shared/types/lead-types.types';

export const LEAD_TYPES_LOAD = '[LEAD_TYPES] Load lead types';
export const LEAD_TYPES_LOAD_SUCCESS = '[LEAD_TYPES] Load lead types successful';
export const LEAD_TYPES_LOAD_ERROR = '[LEAD_TYPES] Load lead types failed';

export class LeadTypesLoad implements Action {
  readonly type = LEAD_TYPES_LOAD;
  constructor(public statuses: LeadType[]) {}
}

export class LeadTypesLoadSuccess implements Action {
  readonly type = LEAD_TYPES_LOAD_SUCCESS;
  constructor(public statuses: LeadType[]) {}
}

export class LeadTypesLoadError implements Action {
  readonly type = LEAD_TYPES_LOAD_ERROR;
}

export type LeadTypesActions = LeadTypesLoad | LeadTypesLoadSuccess | LeadTypesLoadError;
