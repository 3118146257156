import { createAction, props } from '@ngrx/store';
import { Company } from 'src/app/shared/types/company.types';

export const loadCompanies = createAction('[Company] Load Companies');
export const loadCompaniesSuccess = createAction('[Company] Load Companies Success', props<{ companies: Company[] }>());
export const loadCompaniesFailure = createAction('[Company] Load Companies Failure', props<{ error: any }>());

export const loadCompany = createAction('[Company] Load Company', props<{ companyID: number }>());
export const loadCompanySuccess = createAction('[Company] Load Company Success', props<{ company: Company }>());
export const loadCompanyFailure = createAction('[Company] Load Company Failure', props<{ error: any }>());

export const createCompany = createAction('[Company] Create Company', props<{ company: Partial<Company> }>());
export const createCompanySuccess = createAction('[Company] Create Company Success', props<{ company: Company }>());
export const createCompanyFailure = createAction('[Company] Create Company Failure', props<{ error: any }>());

export const updateCompany = createAction('[Company] Update Company', props<{ companyID: number; company: Partial<Company> }>());
export const updateCompanySuccess = createAction('[Company] Update Company Success', props<{ company: Company }>());
export const updateCompanyFailure = createAction('[Company] Update Company Failure', props<{ error: any }>());

export const deleteCompany = createAction('[Company] Delete Company', props<{ companyID: number }>());
export const deleteCompanySuccess = createAction('[Company] Delete Company Success', props<{ companyID: number }>());
export const deleteCompanyFailure = createAction('[Company] Delete Company Failure', props<{ error: any }>());

export const CompanySetInitial = createAction('[Company] Set Initial');
